import React from 'react';
import { ListGroup, Badge } from 'react-bootstrap';

function EmployeeSearchResults({ results, visible, departments }) {
    //console.log('EmployeeSearchResults props:', { results, visible });

    if (!visible || results.length === 0) {
        //console.log('Not showing results because:', !visible ? 'not visible' : 'no results');
        return null;
    }

    const getStatusBadge = (status) => {
        const variants = {
          'In': 'success',
          'Out': 'danger',
          'Lunch': 'info',
          'Meeting': 'danger',
          'Vacation': 'danger'
        };
        return <h4 className='m-0'><Badge bg={variants[status] || 'secondary'}>{status}</Badge></h4>;
    };

    return (
        <ListGroup className="position-absolute w-100 shadow search-results" style={{ zIndex: 1000 }}>
            {results.map((employee) => (
                <ListGroup.Item key={employee.id} className="search-result-item">
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <div className="fw-bold">
                                {`${employee.FirstName} ${employee.LastName}`}
                            </div>
                            <div className="text-muted small">
                                {departments.find(dept => dept.id === employee.DeptRef?.id)?.Name || 'No Department'}
                            </div>
                            <div className="text-muted small">
                                Ext: {employee.Extension} {employee.CellPhone ? `| ${employee.CellPhone}` : ''}
                            </div>
                            {employee.Comment && (
                                <div className="text-muted small">
                                    {employee.Comment}
                                </div>
                            )}
                        </div>
                        <div className={`status-indicator ${employee.Status?.toLowerCase()}`}>
                            {getStatusBadge(employee.Status)}
                        </div>
                    </div>
                </ListGroup.Item>
            ))}
        </ListGroup>
    );
}

export default EmployeeSearchResults;