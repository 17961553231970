import React, { useState, useEffect } from 'react';
import { Modal, Tab, Tabs, Form, Button, Table, Alert, Nav } from 'react-bootstrap';
import { db } from '../App.jsx';
import { collection, addDoc, updateDoc, deleteDoc, doc, getDocs, setDoc, serverTimestamp, getDoc, writeBatch } from 'firebase/firestore';
import { parse } from 'papaparse';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { GripVertical } from 'react-bootstrap-icons';

function EmployeeSettings({ show, handleClose, isAdmin, currentLinks }) {
  const [employees, setEmployees] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [newEmployee, setNewEmployee] = useState({
    FirstName: '',
    LastName: '',
    Extension: '',
    CellPhone: '',
    Username: '',
    DeptRef: '',
    Status: 'Out',
    Visible: true,
    isAdmin: false,
    Comment: '',
    Rank: 0
  });
  const [message, setMessage] = useState({ text: '', type: '' });
  const [editingEmployee, setEditingEmployee] = useState(null);
  const [editingDepartment, setEditingDepartment] = useState(null);
  const [newDepartment, setNewDepartment] = useState({
    Name: '',
    Visible: true
  });
  const [newLink, setNewLink] = useState({
    Name: '',
    URL: '',
    Order: 0,
    isForm: false
  });
  const [links, setLinks] = useState([]);
  const [editingLink, setEditingLink] = useState(null);
  const [csvData, setCsvData] = useState(null);
  const [parsedFile, setParsedFile] = useState(null);

  // Fetch employees
  useEffect(() => {
    const fetchEmployees = async () => {
      const querySnapshot = await getDocs(collection(db, "Employees"));
      setEmployees(querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })));
    };
    fetchEmployees();
  }, [show]); // Refetch when modal opens

  // Add departments fetch to the existing useEffect
  useEffect(() => {
    const fetchData = async () => {
      const employeesSnapshot = await getDocs(collection(db, "Employees"));
      setEmployees(employeesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })));

      const departmentsSnapshot = await getDocs(collection(db, "Departments"));
      const deptData = departmentsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setDepartments(deptData.sort((a, b) => a.Rank - b.Rank));

      const linksSnapshot = await getDocs(collection(db, "Links"));
      setLinks(linksSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })));
    };
    fetchData();
  }, [show]);

  const handleAddEmployee = async (e) => {
    e.preventDefault();
    console.log('Form submitted', newEmployee);

    try {
      // Validate required fields
      if (!newEmployee.FirstName || !newEmployee.LastName || !newEmployee.Username || !newEmployee.DeptRef) {
        setMessage({ text: 'Please fill in all required fields', type: 'danger' });
        return;
      }

      // Create a proper reference for DeptRef
      const [_, collectionName, docId] = newEmployee.DeptRef.split('/');
      const deptDocRef = doc(db, collectionName, docId);

      // Find all employees in the same department and get the highest rank
      const deptEmployees = employees.filter(emp => emp.DeptRef?.id === docId);
      const maxRank = deptEmployees.reduce((max, emp) => Math.max(max, emp.Rank || 0), -1);

      // Create the employee object with the proper reference and automatically calculated rank
      const employeeData = {
        ...newEmployee,
        DeptRef: deptDocRef,
        Comment: '',
        LastUpdated: serverTimestamp(),
        Rank: maxRank + 1  // Set rank to be highest in department + 1
      };

      // Create employee document with Username as the ID
      const employeeRef = doc(db, "Employees", newEmployee.Username);
      await setDoc(employeeRef, employeeData);

      // Create corresponding empty token document
      await setDoc(doc(db, "tokens", employeeRef.id), {
        userId: employeeRef.id,
        email: newEmployee.Username,
        created: serverTimestamp()
      });

      // If employee is an admin, create admin document
      if (newEmployee.isAdmin) {
        await setDoc(doc(db, "Admins", newEmployee.Username), {
          email: newEmployee.Username,
          created: serverTimestamp()
        });
      }

      console.log('Employee and related documents created with ID:', employeeRef.id);
      setMessage({ text: 'Employee added successfully!', type: 'success' });

      // Reset form
      setNewEmployee({
        FirstName: '',
        LastName: '',
        Extension: '',
        CellPhone: '',
        Username: '',
        DeptRef: '',
        Status: 'Out',
        Visible: true,
        isAdmin: false,
        Comment: '',
        Rank: 0
      });
      
      // Refresh employee list
      const querySnapshot = await getDocs(collection(db, "Employees"));
      setEmployees(querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })));
    } catch (error) {
      console.error('Error adding employee:', error);
      setMessage({ text: 'Error adding employee: ' + error.message, type: 'danger' });
    }
  };

  // When deleting an employee, also delete their token and admin documents
  const handleDeleteEmployee = async (id) => {
    if (window.confirm('Are you sure you want to delete this employee?')) {
      try {
        // Get employee data first to check if they're an admin
        const employeeDoc = await getDoc(doc(db, "Employees", id));
        const employeeData = employeeDoc.data();

        // Delete employee document
        await deleteDoc(doc(db, "Employees", id));
        
        // Delete corresponding token document
        await deleteDoc(doc(db, "tokens", id));

        // If employee was an admin, delete their admin document
        if (employeeData.isAdmin) {
          await deleteDoc(doc(db, "Admins", id));
        }
        
        setEmployees(employees.filter(emp => emp.id !== id));
        setMessage({ text: 'Employee deleted successfully!', type: 'success' });
      } catch (error) {
        setMessage({ text: 'Error deleting employee: ' + error.message, type: 'danger' });
      }
    }
  };

  // Edit employee
  const handleEditEmployee = async (id, updatedData) => {
    try {
      await updateDoc(doc(db, "Employees", id), updatedData);
      setEmployees(employees.map(emp => 
        emp.id === id ? { ...emp, ...updatedData } : emp
      ));
      setMessage({ text: 'Employee updated successfully!', type: 'success' });
    } catch (error) {
      setMessage({ text: 'Error updating employee: ' + error.message, type: 'danger' });
    }
  };

  const handleStartEdit = (employee) => {
    setEditingEmployee({ ...employee });
  };

  const handleCancelEdit = () => {
    setEditingEmployee(null);
  };

  const handleSaveEdit = async (id) => {
    try {
      const { id: _, ...updateData } = editingEmployee;
      
      // If DeptRef is a string path, convert it to a reference
      if (typeof updateData.DeptRef === 'string') {
        updateData.DeptRef = doc(db, "Departments", updateData.DeptRef);
      }
      
      await updateDoc(doc(db, "Employees", id), updateData);
      setEmployees(employees.map(emp => 
        emp.id === id ? { ...editingEmployee } : emp
      ));
      setMessage({ text: 'Employee updated successfully!', type: 'success' });
      setEditingEmployee(null);
    } catch (error) {
      setMessage({ text: 'Error updating employee: ' + error.message, type: 'danger' });
    }
  };

  // Department management functions
  const handleDeleteDepartment = async (id) => {
    if (window.confirm('Are you sure you want to delete this department?')) {
      try {
        await deleteDoc(doc(db, "Departments", id));
        setDepartments(departments.filter(dept => dept.id !== id));
        setMessage({ text: 'Department deleted successfully!', type: 'success' });
      } catch (error) {
        setMessage({ text: 'Error deleting department: ' + error.message, type: 'danger' });
      }
    }
  };

  const handleStartEditDepartment = (department) => {
    setEditingDepartment({ ...department });
  };

  const handleCancelEditDepartment = () => {
    setEditingDepartment(null);
  };

  const handleSaveEditDepartment = async (id) => {
    try {
      const { id: _, ...updateData } = editingDepartment;
      await updateDoc(doc(db, "Departments", id), updateData);
      setDepartments(departments.map(dept => 
        dept.id === id ? { ...editingDepartment } : dept
      ).sort((a, b) => a.Rank - b.Rank));
      setMessage({ text: 'Department updated successfully!', type: 'success' });
      setEditingDepartment(null);
    } catch (error) {
      setMessage({ text: 'Error updating department: ' + error.message, type: 'danger' });
    }
  };

  const handleAddDepartment = async (e) => {
    e.preventDefault();
    try {
      // Validate required fields
      if (!newDepartment.Name) {
        setMessage({ text: 'Please fill in the department name', type: 'danger' });
        return;
      }

      // Calculate the new rank value (highest existing rank + 1)
      const maxRank = departments.reduce((max, dept) => Math.max(max, dept.Rank || 0), -1);
      const departmentData = {
        ...newDepartment,
        Name: newDepartment.Name,
        Rank: maxRank + 1,
        Visible: newDepartment.Visible
      };

      // Instead of using addDoc, use setDoc with the Name as the document ID
      const departmentRef = doc(db, "Departments", departmentData.Name);
      await setDoc(departmentRef, departmentData);
      setMessage({ text: 'Department added successfully!', type: 'success' });
      
      // Reset form
      setNewDepartment({
        Name: '',
        Visible: true
      });
      
      // Refresh department list
      const departmentsSnapshot = await getDocs(collection(db, "Departments"));
      const deptData = departmentsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setDepartments(deptData.sort((a, b) => a.Rank - b.Rank));
    } catch (error) {
      console.error('Error adding department:', error);
      setMessage({ text: 'Error adding department: ' + error.message, type: 'danger' });
    }
  };

  // Add this new function to handle rank changes
  const handleChangeRank = async (departmentId, direction) => {
    try {
      const currentDept = departments.find(d => d.id === departmentId);
      const currentRank = currentDept.Rank;
      
      // Find adjacent department based on direction
      const adjacentDept = departments.find(d => 
        direction === 'up' ? d.Rank === currentRank - 1 : d.Rank === currentRank + 1
      );
      
      if (!adjacentDept) return; // No adjacent department to swap with

      // Swap ranks
      await updateDoc(doc(db, "Departments", currentDept.id), {
        Rank: adjacentDept.Rank
      });
      await updateDoc(doc(db, "Departments", adjacentDept.id), {
        Rank: currentRank
      });

      // Update local state
      const updatedDepartments = departments.map(dept => {
        if (dept.id === currentDept.id) {
          return { ...dept, Rank: adjacentDept.Rank };
        }
        if (dept.id === adjacentDept.id) {
          return { ...dept, Rank: currentRank };
        }
        return dept;
      }).sort((a, b) => a.Rank - b.Rank);

      setDepartments(updatedDepartments);
      setMessage({ text: 'Department order updated successfully!', type: 'success' });
    } catch (error) {
      setMessage({ text: 'Error updating department order: ' + error.message, type: 'danger' });
    }
  };

  const handleAddLink = async (e) => {
    e.preventDefault();
    try {
      if (!newLink.Name || !newLink.URL) {
        setMessage({ text: 'Please fill in all required fields', type: 'danger' });
        return;
      }

      // Calculate the new order
      const maxOrder = links.reduce((max, link) => Math.max(max, link.Order || 0), 0);
      const linkData = {
        ...newLink,
        Order: maxOrder + 1
      };

      await addDoc(collection(db, "Links"), linkData);
      setMessage({ text: 'Link added successfully!', type: 'success' });
      
      // Reset form
      setNewLink({
        Name: '',
        URL: '',
        Order: 0,
        isForm: false
      });
      
      // Refresh links list
      const linksSnapshot = await getDocs(collection(db, "Links"));
      setLinks(linksSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })));
    } catch (error) {
      console.error('Error adding link:', error);
      setMessage({ text: 'Error adding link: ' + error.message, type: 'danger' });
    }
  };

  const handleDeleteLink = async (id) => {
    if (window.confirm('Are you sure you want to delete this link?')) {
      try {
        await deleteDoc(doc(db, "Links", id));
        setMessage({ text: 'Link deleted successfully!', type: 'success' });
      } catch (error) {
        setMessage({ text: 'Error deleting link: ' + error.message, type: 'danger' });
      }
    }
  };

  const handleStartEditLink = (link) => {
    setEditingLink({ ...link });
  };

  const handleCancelEditLink = () => {
    setEditingLink(null);
  };

  const handleSaveEditLink = async (id) => {
    try {
      const { id: _, ...updateData } = editingLink;
      await updateDoc(doc(db, "Links", id), updateData);
      setMessage({ text: 'Link updated successfully!', type: 'success' });
      setEditingLink(null);
    } catch (error) {
      setMessage({ text: 'Error updating link: ' + error.message, type: 'danger' });
    }
  };

  const handleChangeLinkOrder = async (linkId, direction) => {
    try {
      const currentLink = currentLinks.find(l => l.id === linkId);
      const currentOrder = currentLink.Order;
      
      // Find adjacent link based on direction
      const adjacentLink = currentLinks.find(l => 
        direction === 'up' ? l.Order === currentOrder - 1 : l.Order === currentOrder + 1
      );
      
      if (!adjacentLink) return; // No adjacent link to swap with

      // Swap orders
      await updateDoc(doc(db, "Links", currentLink.id), {
        Order: adjacentLink.Order
      });
      await updateDoc(doc(db, "Links", adjacentLink.id), {
        Order: currentOrder
      });

      setMessage({ text: 'Link order updated successfully!', type: 'success' });
    } catch (error) {
      setMessage({ text: 'Error updating link order: ' + error.message, type: 'danger' });
    }
  };

  // Modify the toggle admin function to handle the Admins collection
  const handleToggleAdmin = async (employee) => {
    try {
      const newAdminStatus = !employee.isAdmin;
      
      // Update employee document
      await updateDoc(doc(db, "Employees", employee.id), {
        isAdmin: newAdminStatus
      });

      // Handle Admins collection document
      if (newAdminStatus) {
        // Add to Admins collection
        await setDoc(doc(db, "Admins", employee.id), {
          email: employee.Username,
          created: serverTimestamp()
        });
      } else {
        // Remove from Admins collection
        await deleteDoc(doc(db, "Admins", employee.id));
      }

      // Update local state
      setEmployees(employees.map(emp => 
        emp.id === employee.id ? { ...emp, isAdmin: newAdminStatus } : emp
      ));
      setMessage({ 
        text: `Admin status ${newAdminStatus ? 'granted to' : 'removed from'} ${employee.FirstName} ${employee.LastName}`, 
        type: 'success' 
      });
    } catch (error) {
      console.error('Error toggling admin status:', error);
      setMessage({ text: 'Error updating admin status: ' + error.message, type: 'danger' });
    }
  };

  // Add this function to batch update existing employees with ranks
  const updateExistingEmployeesRanks = async () => {
    const querySnapshot = await getDocs(collection(db, "Employees"));
    const batch = writeBatch(db);
    
    // Group employees by department
    const deptEmployees = {};
    querySnapshot.forEach(doc => {
      const data = doc.data();
      const deptId = data.DeptRef?.id || 'unassigned';
      if (!deptEmployees[deptId]) {
        deptEmployees[deptId] = [];
      }
      deptEmployees[deptId].push({ id: doc.id, ...data });
    });

    // Update ranks within each department
    Object.values(deptEmployees).forEach(empGroup => {
      empGroup.forEach((emp, index) => {
        if (emp.Rank === undefined) {
          const empRef = doc(db, "Employees", emp.id);
          batch.update(empRef, { Rank: index });
        }
      });
    });

    await batch.commit();
  };

  // Call this function once to initialize ranks for existing employees
  useEffect(() => {
    if (show) {
      updateExistingEmployeesRanks();
    }
  }, [show]);

  const handleCsvImport = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      console.log('No file selected');
      return;
    }

    console.log('Parsing CSV file:', file.name);

    try {
      const result = await new Promise((resolve, reject) => {
        parse(file, {
          header: true,
          skipEmptyLines: true,
          complete: resolve,
          error: reject,
        });
      });

      console.log('CSV Parse Result:', result);
      setCsvData(result.data);
      setParsedFile(file);
    } catch (error) {
      console.error('Error parsing CSV:', error);
      setMessage({ text: 'Error parsing CSV: ' + error.message, type: 'danger' });
    }

    // Reset the file input
    event.target.value = '';
  };

  const handleConfirmImport = async () => {
    if (!csvData) return;

    try {
      const batch = writeBatch(db);
      let successCount = 0;
      let errorCount = 0;
      
      for (const row of csvData) {
        try {
          // Validate required fields
          if (!row.FirstName || !row.LastName || !row.Username || !row.DeptRef) {
            console.error('Missing required fields in row:', row);
            errorCount++;
            continue;
          }

          const employeeRef = doc(db, "Employees", row.Username);
          const deptRef = doc(db, "Departments", row.DeptRef);

          const employeeData = {
            FirstName: row.FirstName,
            LastName: row.LastName,
            Username: row.Username,
            Extension: row.Extension || '',
            CellPhone: row.CellPhone || '',
            DeptRef: deptRef,
            Status: 'Out',
            Visible: row.Visible === 'false' ? false : true,
            isAdmin: row.isAdmin === 'true',
            Comment: '',
            Rank: parseInt(row.Rank) || 0,
            LastUpdated: serverTimestamp()
          };

          batch.set(employeeRef, employeeData);

          // Create corresponding token document
          batch.set(doc(db, "tokens", row.Username), {
            userId: row.Username,
            email: row.Username,
            created: serverTimestamp()
          });

          // If employee is admin, create admin document
          if (employeeData.isAdmin) {
            batch.set(doc(db, "Admins", row.Username), {
              email: row.Username,
              created: serverTimestamp()
            });
          }

          successCount++;

        } catch (error) {
          console.error('Error processing individual row:', row);
          console.error('Error details:', error);
          errorCount++;
        }
      }

      await batch.commit();
      setMessage({ 
        text: `Import complete! ${successCount} employees added successfully, ${errorCount} failed.`, 
        type: 'success' 
      });

      // Refresh employee list
      const querySnapshot = await getDocs(collection(db, "Employees"));
      setEmployees(querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })));

      // Clear the preview
      setCsvData(null);
      setParsedFile(null);

    } catch (error) {
      console.error('Error importing CSV:', error);
      setMessage({ text: 'Error importing CSV: ' + error.message, type: 'danger' });
    }
  };

  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    const reorderedDepartments = Array.from(departments);
    const [movedDepartment] = reorderedDepartments.splice(result.source.index, 1);
    reorderedDepartments.splice(result.destination.index, 0, movedDepartment);

    // Create updated departments array with new ranks
    const updatedDepartments = reorderedDepartments.map((dept, index) => ({
      ...dept,
      Rank: index
    }));

    // Update the Rank field based on the new order
    const batch = writeBatch(db);
    updatedDepartments.forEach((dept) => {
      batch.update(doc(db, "Departments", dept.id), { Rank: dept.Rank });
    });

    try {
      await batch.commit();
      setDepartments(updatedDepartments); // Update local state with new ranks
      setMessage({ text: 'Department order updated successfully!', type: 'success' });
    } catch (error) {
      setMessage({ text: 'Error updating department order: ' + error.message, type: 'danger' });
    }
  };

  const handleLinkDragEnd = async (result) => {
    if (!result.destination) return;

    const reorderedLinks = Array.from(links);
    const [movedLink] = reorderedLinks.splice(result.source.index, 1);
    reorderedLinks.splice(result.destination.index, 0, movedLink);

    // Create updated links array with new order values
    const updatedLinks = reorderedLinks.map((link, index) => ({
      ...link,
      Order: index
    }));

    // Update the Order field based on the new order
    const batch = writeBatch(db);
    updatedLinks.forEach((link) => {
      batch.update(doc(db, "Links", link.id), { Order: link.Order });
    });

    try {
      await batch.commit();
      setLinks(updatedLinks); // Update local state with new orders
      setMessage({ text: 'Link order updated successfully!', type: 'success' });
    } catch (error) {
      setMessage({ text: 'Error updating link order: ' + error.message, type: 'danger' });
    }
  };

  if (!isAdmin) {
    return null;
  }

  return (
    <Modal show={show} onHide={handleClose} size="xl" style={{ backdropFilter: 'blur(5px)' }}>
      <Modal.Header closeButton>
        <Modal.Title as="h3">Manage InOut</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message.text && (
          <Alert variant={message.type} dismissible onClose={() => setMessage({ text: '', type: '' })}>
            {message.text}
          </Alert>
        )}
        
        <Tab.Container defaultActiveKey="add">
          <div className="d-flex">
            {/* Left side - Vertical tabs */}
            <div className="nav flex-column nav-pills me-3 bg-secondary-subtle rounded-2 p-1" style={{ minWidth: '200px' }}>
              <Nav variant="pills" className="flex-column">
                <Nav.Link eventKey="add" className="text-start">Add Employee</Nav.Link>
                <Nav.Link eventKey="manage" className="text-start">Manage Employees</Nav.Link>
                <Nav.Link eventKey="addDepartment" className="text-start">Add Department</Nav.Link>
                <Nav.Link eventKey="departments" className="text-start">Manage Departments</Nav.Link>
                <Nav.Link eventKey="addLink" className="text-start">Add Link</Nav.Link>
                <Nav.Link eventKey="manageLinks" className="text-start">Manage Links</Nav.Link>
              </Nav>
            </div>

            {/* Right side - Content */}
            <div className="flex-grow-1">
              <Tab.Content>
                <Tab.Pane eventKey="add">
                  <Form onSubmit={handleAddEmployee} className="mb-3">
                    <h3>Add Employee</h3>
                    <hr />
                    <div className="row">
                      <div className="col-md-3">
                        <Form.Group className="mb-3">
                          <Form.Control
                            type="text"
                            placeholder="First Name"
                            value={newEmployee.FirstName}
                            onChange={(e) => setNewEmployee({...newEmployee, FirstName: e.target.value})}
                            tabIndex={1}
                            required
                          />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Control
                            type="text"
                            placeholder="Extension"
                            value={newEmployee.Extension}
                            onChange={(e) => setNewEmployee({...newEmployee, Extension: e.target.value})}
                            tabIndex={3}
                            required
                          />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Select
                            value={newEmployee.DeptRef}
                            onChange={(e) => setNewEmployee({...newEmployee, DeptRef: e.target.value})}
                            tabIndex={5}
                            required
                          >
                            <option value="">Select Department</option>
                            {departments.map((dept) => (
                              <option key={dept.id} value={`/Departments/${dept.id}`}>
                                {dept.Name}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Check
                            type="checkbox"
                            id="visible-checkbox"
                            label="Visible"
                            checked={Boolean(newEmployee.Visible)}
                            onChange={(e) => {
                              const newValue = e.target.checked;
                              console.log('Changing Visible to:', newValue);
                              setNewEmployee(prev => ({
                                ...prev,
                                Visible: newValue
                              }));
                            }}
                            tabIndex={7}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Rank</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Employee Rank"
                            value={newEmployee.Rank}
                            onChange={(e) => setNewEmployee({...newEmployee, Rank: parseInt(e.target.value)})}
                            tabIndex={9}
                            readOnly
                          />
                        </Form.Group>
                      </div>

                      <div className="col-md-3 border-end">
                        <Form.Group className="mb-3">
                          <Form.Control
                            type="text"
                            placeholder="Last Name"
                            value={newEmployee.LastName}
                            onChange={(e) => setNewEmployee({...newEmployee, LastName: e.target.value})}
                            tabIndex={2}
                            required
                        />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Control
                            type="text"
                            placeholder="Cell Phone"
                            value={newEmployee.CellPhone}
                            onChange={(e) => setNewEmployee({...newEmployee, CellPhone: e.target.value})}
                            tabIndex={4}
                            required
                        />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Control
                            type="text"
                            placeholder="Username"
                            value={newEmployee.Username}
                            onChange={(e) => setNewEmployee({...newEmployee, Username: e.target.value})}
                            tabIndex={6}
                            required
                        />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Check
                            type="checkbox"
                            id="admin-checkbox"
                            label="Admin Access"
                            checked={Boolean(newEmployee.isAdmin)}
                            onChange={(e) => {
                              const newValue = e.target.checked;
                              console.log('Changing isAdmin to:', newValue);
                              setNewEmployee(prev => ({
                                ...prev,
                                isAdmin: newValue
                              }));
                            }}
                            tabIndex={8}
                        />
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-4">
                          <h4>Import Multiple Employees</h4>
                          <hr />
                          <Form.Group>
                            <Form.Label>Import from CSV</Form.Label>
                            <Form.Control
                              type="file"
                              accept=".csv"
                              onChange={handleCsvImport}
                            />
                            <Form.Text className="text-muted">
                              CSV should include columns: FirstName, LastName, Username, DeptRef, Extension (optional), 
                              CellPhone (optional), Visible (optional), isAdmin (optional), Rank (optional)
                            </Form.Text>
                          </Form.Group>

                          {csvData && (
                            <div className="mt-3">
                              <div className="d-flex justify-content-between align-items-center mb-2">
                                <h6 className="mb-0">Preview: {parsedFile?.name}</h6>
                                <div>
                                  <Button 
                                    variant="secondary" 
                                    size="sm" 
                                    onClick={() => {
                                      setCsvData(null);
                                      setParsedFile(null);
                                    }}
                                    className="me-2"
                                  >
                                    Cancel
                                  </Button>
                                  <Button 
                                    variant="primary" 
                                    size="sm" 
                                    onClick={handleConfirmImport}
                                  >
                                    Import {csvData.length} Employees
                                  </Button>
                                </div>
                              </div>

                              <div className="table-responsive" style={{ maxHeight: '300px' }}>
                                <Table striped bordered hover size="sm">
                                  <thead>
                                    <tr>
                                      <th>First Name</th>
                                      <th>Last Name</th>
                                      <th>Username</th>
                                      <th>Department</th>
                                      <th>Extension</th>
                                      <th>Cell Phone</th>
                                      <th>Admin</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {csvData.slice(0, 10).map((row, index) => (
                                      <tr key={index}>
                                        <td>{row.FirstName}</td>
                                        <td>{row.LastName}</td>
                                        <td>{row.Username}</td>
                                        <td>{row.DeptRef}</td>
                                        <td>{row.Extension}</td>
                                        <td>{row.CellPhone}</td>
                                        <td>{row.isAdmin === 'true' ? 'Yes' : 'No'}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              </div>
                              
                              {csvData.length > 10 && (
                                <p className="text-muted mt-2 small">
                                  Showing first 10 of {csvData.length} records...
                                </p>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    

                    <Button 
                      variant="primary" 
                      className="ms-2"
                      onClick={(e) => handleAddEmployee(e)}
                      tabIndex={10}
                    >
                      Add Employee
                    </Button>
                  </Form>
                </Tab.Pane>

                <Tab.Pane eventKey="manage">
                  <Form.Group className="mb-3">
                    <h3>Manage Employees</h3>
                    <hr />
                    <Form.Label>Select Employee to Manage</Form.Label>
                    <Form.Select
                      value={editingEmployee?.id || ''}
                      onChange={(e) => {
                        const selectedEmployee = employees.find(emp => emp.id === e.target.value);
                        if (selectedEmployee) {
                          handleStartEdit(selectedEmployee);
                        } else {
                          handleCancelEdit();
                        }
                      }}
                    >
                      <option value="">Choose an employee...</option>
                      {employees
                        .sort((a, b) => a.FirstName.localeCompare(b.FirstName))
                        .map((employee) => (
                          <option key={employee.id} value={employee.id}>
                            {`${employee.FirstName} ${employee.LastName} - ${departments.find(dept => dept.id === employee.DeptRef?.id)?.Name || 'No Department'}`}
                          </option>
                      ))}
                    </Form.Select>
                  </Form.Group>

                  {editingEmployee && (
                    <div className="mt-4">
                      <Form>
                        <div className="row">
                          <div className="col-md-6">
                            <Form.Group className="mb-3">
                              <Form.Label>First Name</Form.Label>
                              <Form.Control
                                type="text"
                                value={editingEmployee.FirstName}
                                onChange={(e) => setEditingEmployee({
                                  ...editingEmployee,
                                  FirstName: e.target.value
                                })}
                              />
                            </Form.Group>

                            <Form.Group className="mb-3">
                              <Form.Label>Last Name</Form.Label>
                              <Form.Control
                                type="text"
                                value={editingEmployee.LastName}
                                onChange={(e) => setEditingEmployee({
                                  ...editingEmployee,
                                  LastName: e.target.value
                                })}
                              />
                            </Form.Group>

                            <Form.Group className="mb-3">
                              <Form.Label>Username</Form.Label>
                              <Form.Control
                                type="text"
                                value={editingEmployee.Username}
                                onChange={(e) => setEditingEmployee({
                                  ...editingEmployee,
                                  Username: e.target.value
                                })}
                              />
                            </Form.Group>

                            <Form.Group className="mb-3">
                              <Form.Label>Cell Phone</Form.Label>
                              <Form.Control
                                type="text"
                                value={editingEmployee.CellPhone || ''}
                                onChange={(e) => setEditingEmployee({
                                  ...editingEmployee,
                                  CellPhone: e.target.value
                                })}
                              />
                            </Form.Group>
                          </div>

                          <div className="col-md-6">
                            <Form.Group className="mb-3">
                              <Form.Label>Department</Form.Label>
                              <Form.Select
                                value={editingEmployee.DeptRef?.id || ''}
                                onChange={(e) => {
                                  const deptRef = doc(db, "Departments", e.target.value);
                                  setEditingEmployee({
                                    ...editingEmployee,
                                    DeptRef: deptRef
                                  });
                                }}
                              >
                                <option value="">Select Department</option>
                                {departments.map((dept) => (
                                  <option key={dept.id} value={dept.id}>
                                    {dept.Name}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3">
                              <Form.Check
                                type="checkbox"
                                label="Visible"
                                checked={Boolean(editingEmployee.Visible)}
                                onChange={(e) => setEditingEmployee({
                                  ...editingEmployee,
                                  Visible: e.target.checked
                                })}
                              />
                            </Form.Group>

                            <Form.Group className="mb-3">
                              <Form.Check
                                type="checkbox"
                                label="Admin Access"
                                checked={Boolean(editingEmployee.isAdmin)}
                                onChange={(e) => setEditingEmployee({
                                  ...editingEmployee,
                                  isAdmin: e.target.checked
                                })}
                              />
                            </Form.Group>
                          </div>
                        </div>

                        <div className="mt-3">
                          <Button
                            variant="success"
                            onClick={() => handleSaveEdit(editingEmployee.id)}
                            className="me-2"
                          >
                            Save Changes
                          </Button>
                          <Button
                            variant="secondary"
                            onClick={handleCancelEdit}
                            className="me-2"
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="danger"
                            onClick={() => {
                              if (window.confirm('Are you sure you want to delete this employee?')) {
                                handleDeleteEmployee(editingEmployee.id);
                                handleCancelEdit();
                              }
                            }}
                          >
                            Delete Employee
                          </Button>
                        </div>
                      </Form>
                    </div>
                  )}
                </Tab.Pane>

                <Tab.Pane eventKey="addDepartment">
                  <Form onSubmit={handleAddDepartment} className="mb-3">
                    <h3>Add Department</h3>
                    <hr />
                    <div className="row">
                      <div className="col-md-6">
                        <Form.Group className="mb-3">
                          <Form.Label>Department Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Department Name"
                            value={newDepartment.Name}
                            onChange={(e) => setNewDepartment({...newDepartment, Name: e.target.value})}
                            required
                          />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Check
                            type="checkbox"
                            id="department-visible-checkbox"
                            label="Visible"
                            checked={newDepartment.Visible}
                            onChange={(e) => setNewDepartment({...newDepartment, Visible: e.target.checked})}
                          />
                        </Form.Group>

                        <Button 
                          variant="primary" 
                          type="submit"
                        >
                          Add Department
                        </Button>
                      </div>
                    </div>
                  </Form>
                </Tab.Pane>

                <Tab.Pane eventKey="departments">
                  <h3>Manage Departments</h3>
                  <hr />
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="departments">
                      {(provided) => (
                        <Table responsive {...provided.droppableProps} ref={provided.innerRef} id="manageDepartmentsTable">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Rank</th>
                              <th>Visible</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {departments.map((department, index) => (
                              <Draggable key={department.id} draggableId={department.id} index={index}>
                                {(provided) => (
                                  <tr ref={provided.innerRef} {...provided.draggableProps}>
                                    <td>
                                      <span {...provided.dragHandleProps} className="me-2 text-secondary" style={{ cursor: 'move' }}>
                                        <GripVertical />
                                      </span>
                                      {editingDepartment?.id === department.id ? (
                                        <Form.Control
                                          type="text"
                                          value={editingDepartment.Name}
                                          onChange={(e) => setEditingDepartment({
                                            ...editingDepartment,
                                            Name: e.target.value
                                          })}
                                        />
                                      ) : (
                                        department.Name
                                      )}
                                    </td>
                                    <td>
                                      {editingDepartment?.id === department.id ? (
                                        <Form.Control
                                          type="number"
                                          value={editingDepartment.Rank}
                                          onChange={(e) => setEditingDepartment({
                                            ...editingDepartment,
                                            Rank: parseInt(e.target.value)
                                          })}
                                        />
                                      ) : (
                                        department.Rank
                                      )}
                                    </td>
                                    <td>
                                      {editingDepartment?.id === department.id ? (
                                        <Form.Check
                                          type="checkbox"
                                          checked={editingDepartment.Visible}
                                          onChange={(e) => setEditingDepartment({
                                            ...editingDepartment,
                                            Visible: e.target.checked
                                          })}
                                        />
                                      ) : (
                                        department.Visible ? 'Yes' : 'No'
                                      )}
                                    </td>
                                    <td>
                                      {editingDepartment?.id === department.id ? (
                                        <>
                                          <Button
                                            variant="success"
                                            size="sm"
                                            onClick={() => handleSaveEditDepartment(department.id)}
                                            className="me-2"
                                          >
                                            Save
                                          </Button>
                                          <Button
                                            variant="secondary"
                                            size="sm"
                                            onClick={handleCancelEditDepartment}
                                          >
                                            Cancel
                                          </Button>
                                        </>
                                      ) : (
                                        <>
                                          <Button
                                            variant="danger"
                                            size="sm"
                                            onClick={() => handleDeleteDepartment(department.id)}
                                            className="me-2"
                                          >
                                            Delete
                                          </Button>
                                          <Button
                                            variant="info"
                                            size="sm"
                                            onClick={() => handleStartEditDepartment(department)}
                                          >
                                            Edit
                                          </Button>
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </tbody>
                        </Table>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Tab.Pane>

                <Tab.Pane eventKey="addLink">
                  <Form onSubmit={handleAddLink} className="mb-3">
                    <h3>Add Link</h3>
                    <hr />
                    <div className="row">
                      <div className="col-md-6">
                        <Form.Group className="mb-3">
                          <Form.Label>Link Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Link Name"
                            value={newLink.Name}
                            onChange={(e) => setNewLink({...newLink, Name: e.target.value})}
                            required
                          />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>URL</Form.Label>
                          <Form.Control
                            type="url"
                            placeholder="https://example.com"
                            value={newLink.URL}
                            onChange={(e) => setNewLink({...newLink, URL: e.target.value})}
                            required
                          />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Check
                            type="checkbox"
                            id="link-form-checkbox"
                            label="Is Form"
                            checked={newLink.isForm}
                            onChange={(e) => setNewLink({...newLink, isForm: e.target.checked})}
                          />
                        </Form.Group>

                        <Button 
                          variant="primary" 
                          type="submit"
                        >
                          Add Link
                        </Button>
                      </div>
                    </div>
                  </Form>
                </Tab.Pane>

                <Tab.Pane eventKey="manageLinks">
                  <h3>Manage Links</h3>
                  <hr />
                  <DragDropContext onDragEnd={handleLinkDragEnd}>
                    <Droppable droppableId="links">
                      {(provided) => (
                        <Table responsive {...provided.droppableProps} ref={provided.innerRef} id="manageLinksTable">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>URL</th>
                              <th>Order</th>
                              <th>Is Form</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {links.sort((a, b) => a.Order - b.Order).map((link, index) => (
                              <Draggable key={link.id} draggableId={link.id} index={index}>
                                {(provided) => (
                                  <tr ref={provided.innerRef} {...provided.draggableProps}>
                                    <td>
                                      <span {...provided.dragHandleProps} className="me-2 text-secondary" style={{ cursor: 'move' }}>
                                        <GripVertical />
                                      </span>
                                      {editingLink?.id === link.id ? (
                                        <Form.Control
                                          type="text"
                                          value={editingLink.Name}
                                          onChange={(e) => setEditingLink({
                                            ...editingLink,
                                            Name: e.target.value
                                          })}
                                        />
                                      ) : (
                                        link.Name
                                      )}
                                    </td>
                                    <td style={{maxWidth: '275px'}} className="text-truncate">
                                      {editingLink?.id === link.id ? (
                                        <Form.Control
                                          type="url"
                                          value={editingLink.URL}
                                          onChange={(e) => setEditingLink({
                                            ...editingLink,
                                            URL: e.target.value
                                          })}
                                        />
                                      ) : (
                                        link.URL
                                      )}
                                    </td>
                                    <td>{link.Order}</td>
                                    <td>
                                      {editingLink?.id === link.id ? (
                                        <Form.Check
                                          type="checkbox"
                                          checked={editingLink.isForm}
                                          onChange={(e) => setEditingLink({
                                            ...editingLink,
                                            isForm: e.target.checked
                                          })}
                                        />
                                      ) : (
                                        link.isForm ? 'Yes' : 'No'
                                      )}
                                    </td>
                                    <td>
                                      {editingLink?.id === link.id ? (
                                        <>
                                          <Button
                                            variant="success"
                                            size="sm"
                                            onClick={() => handleSaveEditLink(link.id)}
                                            className="me-2"
                                          >
                                            Save
                                          </Button>
                                          <Button
                                            variant="secondary"
                                            size="sm"
                                            onClick={handleCancelEditLink}
                                          >
                                            Cancel
                                          </Button>
                                        </>
                                      ) : (
                                        <>
                                          <Button
                                            variant="danger"
                                            size="sm"
                                            onClick={() => handleDeleteLink(link.id)}
                                            className="me-2"
                                          >
                                            Delete
                                          </Button>
                                          <Button
                                            variant="info"
                                            size="sm"
                                            onClick={() => handleStartEditLink(link)}
                                          >
                                            Edit
                                          </Button>
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </tbody>
                        </Table>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EmployeeSettings;