import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { useTheme } from '../contexts/ThemeContext';

function ThemeToggle({ useSwitch = false }) {
  const { isDarkMode, toggleTheme } = useTheme();

  return useSwitch ? (
    <Form.Check 
      type="switch"
      id="theme-switch"
      label={isDarkMode ? 'Dark Mode' : 'Dark Mode'}
      checked={isDarkMode}
      onChange={toggleTheme}
      className="theme-switch"
    />
  ) : (
    <Button
      variant={isDarkMode ? 'light' : 'dark'}
      size="md"
      onClick={toggleTheme}
      className="d-flex align-items-center gap-2"
    >
      {isDarkMode ? (
        <>
          <i className="bi bi-sun-fill"></i>
          Light Mode
        </>
      ) : (
        <>
          <i className="bi bi-moon-fill"></i>
          Dark Mode
        </>
      )}
    </Button>
  );
}

export default ThemeToggle;