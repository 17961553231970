import React, { useState, useEffect, useCallback } from 'react';
import { signOut } from 'firebase/auth';
import { auth1 } from '../App';  // Import the auth instance
import { Nav, Card, Button, Form, Dropdown, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import logo from "../assets/images/Wabash_Center_RGB_cropped.png"
import logoDark from "../assets/images/Wabash_Center_WHITE-colorflames.png"
import { useTheme } from '../contexts/ThemeContext';
import EmployeeSettings from '../components/EmployeeSettings';
import { collection, query, where, getDocs, onSnapshot } from 'firebase/firestore';
import { db } from '../App';  // Import your Firestore instance
import EmployeeSearchResults from './EmployeeSearchResults';
import debounce from 'lodash/debounce';
import ThemeToggle from '../components/ThemeToggle';
import StatusControl from '../components/StatusControl';
import { PinAngleFill, X } from 'react-bootstrap-icons';
import { format } from 'date-fns';

function Sidebar({ isAdmin, employees, pinnedEmployees, onTogglePin }) {
    const [showSettings, setShowSettings] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [links, setLinks] = useState([]);
    const { isDarkMode } = useTheme();
    const [departments, setDepartments] = useState([]);

    const getStatusBadge = (status, lastUpdated) => {
        const variants = {
          'In': 'success',
          'Out': 'danger',
          'Lunch': 'warning',
          'Meeting': 'danger',
          'Vacation': 'danger'
        };
    
        let formattedDate = 'No update time available';
        if (lastUpdated?.toDate) {
          const date = lastUpdated.toDate();
          const today = new Date();
          if (date.toDateString() === today.toDateString()) {
            formattedDate = `Today, ${format(date, 'h:mm a')}`;
          } else {
            formattedDate = format(date, 'PP, h:mm a');
          }
        }
    
        return (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip className="custom-tooltip">
                Last updated: {formattedDate}
              </Tooltip>
            }
          >
            <h4 className='m-0'>
              <Badge bg={variants[status] || 'secondary'} text={isDarkMode ? 'primary' : ''}>
                {status}
              </Badge>
            </h4>
          </OverlayTrigger>
        );
      };

    // Replace the handleSearch function with this safer version
    const handleSearch = useCallback((term) => {
        if (!term.trim()) {
            setSearchResults([]);
            return;
        }

        // Add null checks and make sure employees exists
        if (!employees) {
            // console.log('No employees data available');
            return;
        }

        const searchTerm = term.toLowerCase().trim();
        
        const results = employees.filter(emp => {
            if (!emp) return false;
            
            // Create searchable fields array, converting all values to strings and handling nulls
            const searchableFields = [
                `${emp.FirstName} ${emp.LastName}`.toLowerCase(),
                (emp.Extension || '').toString().toLowerCase(),
                (emp.CellPhone || '').toString().toLowerCase()
            ];

            // Return true if any field contains the search term
            return searchableFields.some(field => field.includes(searchTerm));
        });

        // console.log('Search results:', results); // Debug log
        setSearchResults(results);
    }, [employees]);

    useEffect(() => {
        handleSearch(searchTerm);
    }, [searchTerm, handleSearch]);

    useEffect(() => {
        // Set up real-time listener for links
        const linksRef = collection(db, 'Links');
        const q = query(linksRef);
        
        // Create the subscription
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const fetchedLinks = [];
            querySnapshot.forEach((doc) => {
                fetchedLinks.push({ id: doc.id, ...doc.data() });
            });
            // Sort links by Order field
            fetchedLinks.sort((a, b) => a.Order - b.Order);
            setLinks(fetchedLinks);
        }, (error) => {
            console.error('Error listening to links:', error);
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []); // Empty dependency array since we want this to run once on mount

    useEffect(() => {
        // Set up real-time listener for departments
        const departmentsRef = collection(db, 'Departments');
        const q = query(departmentsRef);
        
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const fetchedDepartments = [];
            querySnapshot.forEach((doc) => {
                fetchedDepartments.push({ id: doc.id, ...doc.data() });
            });
            setDepartments(fetchedDepartments);
        }, (error) => {
            console.error('Error listening to departments:', error);
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []); // Empty dependency array since we want this to run once on mount

    const handleSignOut = async () => {
        try {
            await signOut(auth1);
            // The auth state listener in App.jsx will handle the UI update
        } catch (error) {
            console.error('Error signing out:', error);
            alert('Failed to sign out');
        }
    };

    return (
        <Card className={`${isDarkMode ? 'bg-light-emphasis' : 'bg-secondary-subtle'} h-100 border-0 rounded-0 mb-0`}>
            <Card.Body className="pt-2">
                <Nav className="flex-column align-items-center align-items-md-start">
                    
                    {/* <div className="mb-3 mt-2">
                        <img src={isDarkMode ? logoDark : logo} className="logo w-100 mx-auto" style={{ maxWidth: '325px' }} alt="Logo"/>
                    </div> */}
                    <div className="position-relative mb-3 mt-2 w-100">
                        <Form>
                            <Form.Control
                                type="search"
                                placeholder="Search employees..."
                                value={searchTerm}
                                onChange={(e) => {
                                    //console.log('Search term changed:', e.target.value);
                                    setSearchTerm(e.target.value);
                                }}
                                className="search-input"
                            />
                        </Form>
                        <EmployeeSearchResults 
                            results={searchResults}
                            visible={searchTerm.length > 0}
                            departments={departments}
                        />
                    </div>

                    {pinnedEmployees.length > 0 && (
                        <div className="w-100">
                            <h5 className="mb-2">Pinned Employees</h5>
                            {pinnedEmployees.map(employee => (
                                <Card key={employee.id} className={`${isDarkMode ? 'bg-body' : ''} mb-2 shadow-sm`}>
                                    <Card.Body className="p-2">
                                        <div className="position-relative">
                                            <div className="d-flex flex-wrap align-items-start justify-content-between pe-3">
                                                <div>
                                                    <div className={employee.Status == 'In' ? '' : 'fw-bold'}>
                                                        <span className="pe-1 border-end">{employee.FirstName} {employee.LastName}</span>
                                                        <small className="text-muted ms-1">{employee.Extension}</small>
                                                    </div>
                                                    <div className="text-muted pinnedComment">{employee.Comment}</div>
                                                </div>
                                                
                                                <div>
                                                    {getStatusBadge(employee.Status, employee.LastUpdated)}
                                                </div>
                                            </div>
                                            
                                            <div className="position-absolute top-0 end-0">
                                                <OverlayTrigger
                                                    placement="right"
                                                    overlay={
                                                        <Tooltip>
                                                            Remove from sidebar
                                                        </Tooltip>
                                                    }
                                                >
                                                    <Button
                                                        variant="link"
                                                        size="sm"
                                                        className="p-0"
                                                        onClick={() => onTogglePin(employee)}
                                                    >
                                                        <X className="text-body border" />
                                                    </Button>
                                                </OverlayTrigger>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            ))}
                        <hr></hr></div>
                    )}
                    
                    {links
                        .filter(link => !link.isForm)
                        .sort((a, b) => a.Order - b.Order)
                        .map((link) => (
                            <Nav.Link 
                                key={link.id} 
                                href={link.URL}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="mb-0 p-0"
                            >
                                {link.Name}
                            </Nav.Link>
                        ))}

                    {links.some(link => link.isForm) && (
                        <Dropdown className="mt-1 mb-3">
                            <Dropdown.Toggle variant="outline-secondary" id="forms-dropdown">
                                Forms
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {links
                                    .filter(link => link.isForm)
                                    .sort((a, b) => a.Order - b.Order)
                                    .map((link) => (
                                        <Dropdown.Item 
                                            key={link.id}
                                            href={link.URL}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {link.Name}
                                        </Dropdown.Item>
                                    ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    )}

                    <div className="pt-0 pb-3">
                        <ThemeToggle useSwitch={true} />
                    </div>

                    {isAdmin && (
                        <>
                            <Nav.Link className="p-0">
                                <Button onClick={() => setShowSettings(true)} className={isDarkMode ? 'btn-light' : 'btn-dark'}>
                                    Manage Employees
                                </Button>
                            </Nav.Link>
                        </>
                    )}
                </Nav>
            </Card.Body>
            {showSettings && (
                <EmployeeSettings 
                    show={showSettings}
                    handleClose={() => setShowSettings(false)}
                    isAdmin={isAdmin}
                    currentLinks={links}
                />
            )}
        </Card>
    );
}

export default Sidebar; 