import React from 'react';
import { Navbar as BsNavbar, Container } from 'react-bootstrap';
import { useTheme } from '../contexts/ThemeContext';
import logo from "../assets/images/Wabash_Center_RGB_cropped.png";
import logoDark from "../assets/images/Wabash_Center_WHITE-colorflames.png";
import StatusControlNav from './StatusControlNav';

function Navbar({ currentUser, isAdmin, departments }) {
    const { isDarkMode } = useTheme();
    
    // console.log('Navbar currentUser:', currentUser);
    // console.log('Navbar departments:', departments);

    const userDepartment = currentUser?.DeptRef ? 
        departments?.find(d => d.id === currentUser.DeptRef.id)?.Name : '';

    return (
        <BsNavbar 
            className={`${isDarkMode ? 'bgwcdark' : 'bgwclight'} py-0 z-3`}
            variant={isDarkMode ? 'dark' : 'light'}
        >
            <Container className={`${isDarkMode ? 'navbar-bg-dark' : 'navbar-bg-light'} px-4 py-2 bg-body shadow-sm rounded-bottom-3`}>
                <div className="d-flex flex-column flex-sm-row w-100 align-items-center justify-content-sm-center justify-content-md-between gap-3 flex-nowrap flex-sm-wrap flex-md-nowrap flex-lg-nowrap flex-xl-nowrap flex-xxl-nowrap">
                    <BsNavbar.Brand className="d-flex align-items-center justify-content-center justify-content-sm-center justify-content-md-start justify-content-xl-start justify-content-xxl-start justify-content-sm-start">
                        <img
                            src={isDarkMode ? logoDark : logoDark}
                            className="d-inline-block align-top me-3"
                            alt="Wabash Center Logo"
                        />
                        <h3 className={`${isDarkMode ? 'text-light-subtle' : 'text-light'} mb-0`}>In/Out Board</h3>
                    </BsNavbar.Brand>
                    
                    {currentUser && currentUser.FirstName && (
                        <StatusControlNav 
                            employeeId={currentUser.id}
                            currentUserId={currentUser.id}
                            isAdmin={isAdmin}
                            employeeName={`${currentUser.FirstName} ${currentUser.LastName}`}
                            currentStatus={currentUser.Status}
                            department={userDepartment}
                            profilePhotoUrl={currentUser.photoUrl}
                            lastUpdated={currentUser.LastUpdated}
                            comment={currentUser.Comment}
                        />
                    )}
                </div>
            </Container>
        </BsNavbar>
    );
}

export default Navbar;