import { signInWithMicrosoft } from "../App";

export const isTokenExpired = (lastTokenUpdate) => {
  if (!lastTokenUpdate) return true;
  
  // Convert Firestore timestamp to Date if necessary
  const updateTime = lastTokenUpdate.toDate ? lastTokenUpdate.toDate() : lastTokenUpdate;
  const now = new Date();
  
  // Check if token is older than 1 hour (3600000 milliseconds)
  return now - updateTime > 3600000;
};

export const getUserPhoto = async (accessToken) => {
  try {
    const response = await fetch('https://graph.microsoft.com/v1.0/me/photo/$value', {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch photo: ${response.status}`);
    }

    const blob = await response.blob();
    return URL.createObjectURL(blob);
  } catch (error) {
    console.error('Error fetching user photo:', error);
    return null;
  }
};